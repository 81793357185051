import * as React from 'react'
import WpApi from './wpapi'
import UIAdminObjectCluster from './UIAdminObjectCluster'

import { ReactSortable } from "react-sortablejs";

interface MyProps {
    tup:any
    for_timeline:boolean
}
type MyState = {
    cft_blocks:Array<any>
    clusters:Array<any>
    inputvalue:string
    show_save_order:boolean
    warning:string
}
class UIAdminObjectType extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>


    constructor(props: MyProps) {
        super(props)
	this.on_enter_new_cluster = this.on_enter_new_cluster.bind(this)
	this.on_key_up = this.on_key_up.bind(this)
	this.save_ordered = this.save_ordered.bind(this)

	
	this.state = {
	    inputvalue:'',
	    warning:'',
	    clusters:[],
	    show_save_order:false,
	    cft_blocks:[]
	}
    }
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	let tup = this.props.tup
	let path = '/cft-blocks/admin'
	let d : {[k:string]:string}= {
	    mode: 'get-admin-options',
	    obtype: tup.name,
	    for_timeline: this.props.for_timeline ? 'true' : 'false'
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState( {
		cft_blocks: r.cft_blocks,
		clusters: r.clusters
	    })
	})
	
    }
    on_select_add_to_cluster(val:string, tup:any){
	let d = {
	    cmd: 'add-block-to-cluster',
	    cluster_id: val,
	    obtype: this.props.tup.name,
	    cft_block_key: tup.key
	}
	console.log(d)
	let path = '/cft-blocks/admin'

	WpApi.do_post(path, d,(r) => {
	    if(r.error){
		this.setState({warning:r.error})
	    }else{
		console.log(r)
		window.location.reload()
	    }
	})

    }
    render_select_add_to_cluster(tup:any){
	let optlist = [
	    ['' , '- add to cluster -']
	]
	for(var x of this.state.clusters){
	    if(x.built_in !== "timeversions"){
		optlist.push([x.id, x.title])
	    }
	}
	
	return (
	    <select onChange={(e:any)=> this.on_select_add_to_cluster(e.target.value,tup)}>
		{optlist.map((x:any)=>{
		    let k = x[0]
		    let lab = x[1]
		    return (<option key={k} value={k}>{lab}</option>)
		})}
		</select>
	)
    }
    render_blockrow(tup:any){
	let label = tup.label
	return (
	    <div key={tup.key} className="row">
		<div>{label}</div>
		<div>{tup.ui_label}</div>
		<div>{this.render_select_add_to_cluster(tup)}</div>
		</div>
	)
    }

    render_block_list(){
	let list = this.state.cft_blocks

	return (
	    <div className="cft-block-list">
		<h4> Cft-blocks: </h4>
		{list.map((tup:any) => this.render_blockrow(tup))}
	    </div>
	)
    }

		     
    render_cluster_list(){
	return (
	    <div>
		<h3>Cft-block-clusters</h3>
		<ReactSortable
		    className="cft-cluster-list"
	    list={this.state.clusters}
	    onUpdate={()=>{this.setState({show_save_order:true})}}
		    setList={(newState)=>this.setState({ clusters: newState })}
		>
		    {this.state.clusters.map((tup:any) =>
			<UIAdminObjectCluster
			    key={tup.id}
			    tup={tup}
			    cft_blocks={this.state.cft_blocks} />
		    )}
		</ReactSortable>
		{this.state.show_save_order ? (
		    <button onClick={this.save_ordered}>save order</button>
		):null}
	    </div>
	)
    }
    on_enter_new_cluster(e:any){
	let v = e.target.value
	console.log(` v: ${v}`)
	this.setState({
	    inputvalue:v
	})
	
    }
    render_input_new_cluster(){
	return (
	    <div> add a new cluster
		<input
	    onKeyUp={this.on_key_up}
	    onChange={this.on_enter_new_cluster}
	    value={this.state.inputvalue}
	     />
		</div>
	)
		
	    
    }
    on_key_up(e:any){
	if(e.keyCode === 13){
	    // Enter key was pressed
	    this.new_cluster_submit()
	}
    }
    new_cluster_submit(){
	console.log('submit')
	let tup = this.props.tup
	let d = {
	    label: this.state.inputvalue,
	    cmd: 'add-cluster',
	    for_timeline: this.props.for_timeline,
	    obtype: tup.name
	}
	console.log(d)
	let path = '/cft-blocks/admin'

	WpApi.do_post(path, d,(r) => {
	    console.log(r)
	    let prev = this.state.clusters
	    this.setState({ clusters: [...prev, r.instance]})
	})
    }
    save_ordered(){
	let list = this.state.clusters.map((x:any) => x.id)
	let tup = this.props.tup
	console.log('save ordered')
	console.log(list)
	if(list.length === 0){return}
	let d = {
	    ordering:list,
	    cmd: 'save-ordering',
	    obtype: tup.name
	}
	console.log(d)
	let path = '/cft-blocks/admin'

	WpApi.do_post(path, d,(r) => {
	    console.log(r)
	    window.location.reload()
	})	
	
    }
    render_warnings(){
	if(this.state.warning === ''){
	    return
	}
	return (
	    <div className="warnings">
		{this.state.warning}
	    </div>
	)
	
    }
    render_choose_for_timeline(){
	let tup = this.props.tup
	//let options = [ "yes", "no"]

	if(tup.has_timeline){
	    let linktxt = "timeversions"
	    let url = "?obtype="+tup.name
	    if(!this.props.for_timeline){
		linktxt = "timeline"
		url += "&timeline=true"
	    }
	    return (
		<div> 
		    (click   <a href={url}> here for {linktxt} </a> )
		    </div>
	    )
	}
	return null
    }
    render(){
	let tup = this.props.tup
	let postfix = ''
	if(tup.has_timeline){
	    if(this.props.for_timeline){
		postfix = '(timeline)'
	    }else{
		postfix = '(timeversions)'
	    }
	}

	return (
	    <div className="ui-admin-blocktype">
		<h3>Object-type: {tup.label} {postfix}</h3>
	    {this.render_choose_for_timeline()}
		{this.render_warnings()}
		{this.render_input_new_cluster()}
	    {this.render_cluster_list()}
		{this.render_block_list()}

	    </div>
	)
    }
}


export default UIAdminObjectType
