import * as React from 'react'
import WpApi from './wpapi'
import FeatureInputRow from './FeatureInputRow'
import RelationRow from './RelationRow'
import HrefLink from './HrefLink'
import CftBlockCluster from './CftBlockCluster'
import Expander_helper  from './Expander_helper'
import get_nice_label from './ObjectNiceName'

interface MyProps {
    tup:any
    config:any
    context:any
    append? :string
    mode? :string
    cb_show_votes?:   (e: any) => void    
    cb_parent:   (e: any, cb: (r: any) => void) => void    
}
type MyState = {

    instance:any
    corefeatures: Array<any>
    objectfeatures: Array<any>
    relations: Array<any>
    clusters:Array<any>
    cft_blocks:Array<any>
    
}

class FlexObjectRow extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)

	this.click_show_votes = this.click_show_votes.bind(this)


	this.state = {

	    corefeatures: [],
	    objectfeatures: [],
	    instance:null,
	    clusters:[],
	    cft_blocks:[],
	    relations: []
	}
    }
    
    fetch_content(){
	let path = '/features'
	let d : {[k:string]:string}= {
	    post_id: this.props.tup.id,
	    mode: 'get-options'
	}
	WpApi.do_get(path, d,(r) => {
	    console.log(r)
	    this.setState({
		instance:r.instance,
		clusters: r.clusters,
		cft_blocks:r.cft_blocks

		//corefeatures: r.corefeatures,
		//objectfeatures: r.objectfeatures,
		//relations: r.relations
	    })
	})
	
    }

    render_corefeature(ftup:any){
	let pid = this.props.tup.id
	return (
	    <FeatureInputRow key={ftup.name} tup={ftup} post_id={Number(pid)}
	    		     cb_parent={this.props.cb_parent}
		/>
	)

    }
    render_core_features(){
	let list = this.state.corefeatures
	if(list.length === 0) { return null }	
	return (
	    <div className="features core-features">
		<b> Features </b>
		<div className="body">
		{list.map((tup:any) => this.render_corefeature(tup))}
		</div>
	    </div>
	)
    }
    render_relation(tup:any){
	let pid = this.props.tup.id
	return (
	    <RelationRow instance={this.state.instance}
	    config={this.props.config}
	    key={tup.key} tup={tup} post_id={Number(pid)}
	       cb_parent={this.props.cb_parent}
		/>
	)	
    }
    render_cluster(tup:any){
	let pid = this.props.tup.id

	return (
	    <CftBlockCluster key={tup.id}
	    context={this.props.context}
	    tup={tup} cft_blocks={this.state.cft_blocks}
	    post_id={Number(pid)}
	    instance={this.state.instance}
	    	    config={this.props.config}
	    cb_parent={this.props.cb_parent}
		/>
	)
    }
    render_clusters(){
	return (
	    <div className="clusters features">
		{this.state.clusters.map((tup:any) => this.render_cluster(tup))}
	    </div>
	)
    }
    
    render_obj_relations(){
	let list = this.state.relations
	if(list.length === 0) { return null }
	if(this.state.instance.metatype === "timeline"){
	    return
	}


	return (
	    <div className="features relations">
		<b> Object-relations </b>
		<div className="body">
		    {list.map((tup:any) => this.render_relation(tup))}
		</div>
		</div>
	)
    }
    render_obj_feature(ftup:any){
	let pid = this.props.tup.id
	return (
	    <FeatureInputRow tup={ftup} key={ftup.key} post_id={Number(pid)}
	    cb_parent={this.props.cb_parent}
		/>
	)
    }
    
    render_obj_features(){
	let list = this.state.objectfeatures
	if(list.length === 0) { return null }
	if(this.state.instance.metatype === "timeline"){
	    return
	}

	return (
	    <div className="features object-features">
		<h3> Object-features </h3>
		<div className="body">
		    {list.map((tup:any) => this.render_obj_feature(tup))}
		</div>
	    </div>
	)
    }
    render_uom_for(){
	let tup = this.props.tup
	if(tup.obtype === 'uom'){
	    if(tup.for_relations !== undefined){
		let vals = tup.for_relations.join(", ")
		return (<div> Is built-in UOM for relations: {vals} </div>)
	    }
	}
	return null
    }
    render_hyperlink(){
	let inst = this.props.tup
	let params = 'obtype='+inst.obtype+'&instance='+inst.id
	if(inst.bbgrouplink !== undefined){
	    let url = inst.bbgrouplink + '?instance='+inst.id
		return (
		    <HrefLink page="org-dash" params={url} />
		)
	}
	if(this.props.context === "relation"){
	    return (
		<HrefLink page="obj-dash" params={params}  />
	    )
	}
	return null
    }
    OLDrender_instance(){
	let tup = this.props.tup
	let label = tup.title
	let supr = tup.object_type.cft_supertype
	let timeinfo = null

	if(tup.metatype==="timeperiod-version"){
	    timeinfo = (<div>Time-period: {tup.period } </div>)
	}
	else if(tup.metatype==="year-version"){
	    timeinfo = (<div>Year:  {tup.year} </div>)
	}
	else if(tup.metatype === "timeline"){
	}
	let params = 'obtype='+tup.obtype+'&instance='+tup.id
	return (
	    <div className="identity">
		<div><HrefLink page="obj-dash" params={params}  /></div>
		<div>Object-type: {tup.object_type.label} [{supr} {tup.metatype}]</div>
		<div>Name: {label} </div>
	    {timeinfo}
	    {this.render_uom_for()}
	    </div>
	)
    }
    click_show_votes(e:any){
	console.log('show-votes')
	if(this.props.cb_show_votes === undefined){return}
	this.props.cb_show_votes({
	    'winner': this.props.tup
	})
	e.stopPropagation()
    }
    render_vote_btn(){
	if(this.props.context === "relation"){
	    return (
		<div className="buttons">
		    <div className="show-votes cft-small-icon" onClick={this.click_show_votes}>
		      <img 
			alt="" src={require("./img/icon-history.png")}/>
		    </div>
		</div>
	    )
	}
	return null
    }
    
    render(){
	let tup = this.props.tup
	let otlabel =tup.object_type.label 
	if(tup.object_type.cft_ui_label !== undefined){
	    otlabel = tup.object_type.cft_ui_label
	}
	let nice_label = get_nice_label(tup,tup.title,true)
	let exp = new Expander_helper()
	return (
	    <div key={tup.id} className={"tree-item-child object-view with-expand collapsed"}>
		<div className="header primary" onClick={(e:any)=> {
			exp.toggle_expand(e)
			this.fetch_content()
		    }}>
		    <div className="pre-icon"></div>
		    <div className="label"> {nice_label} </div>
		    {this.render_hyperlink()}
		    {this.render_vote_btn()}
		</div>
		<div className="details body">


	    {this.render_clusters()}
		</div>

	    </div>
	)
    }
}

export default FlexObjectRow

