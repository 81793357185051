import * as React from 'react'
import FlexObjectRow from './FlexObjectRow'
import WpApi from './wpapi'

interface MyProps {
    tup:any
    post_id:number
    config:any
    instance:any
    cb_parent:   (e: any, cb: (r: any) => void) => void

}

type MyState = {
    winners: Array<any>

}
class RelationRow extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)
	this.on_click_add = this.on_click_add.bind(this)
	this.click_show_history = this.click_show_history.bind(this)
	this.cb_show_votes = this.cb_show_votes.bind(this) 

	this.state = {
	    winners:[]
	}
    }
    componentDidMount(){
	let tup = this.props.tup
	if(tup.winners !== undefined){
	    this.setState({winners:tup.winners})
	}
    }    
    on_click_add(){
	console.log('click')
	let lbl = 'Add relation: '+this.props.tup.label
	let d  = {
	    cmd: 'add-relation',
	    relation:this.props.tup,
	    instance:this.props.instance,
	    label: lbl
	}
	this.props.cb_parent(d, (r:any)=>{
	    console.log('callback')
	    console.log(r)
	    if(r.status === "ok"){
		this.reload()
	    }
	})
    }
    reload(){
	console.log('reload')
	let path = '/features'
	let d : {[k:string]:string}= {
	    mode: 'get-rel-winners',
	    object_id: this.props.instance.id,
	    rid: this.props.tup.tup.rid,
	    inverse: this.props.tup.tup.inverse


	}
	WpApi.do_get(path, d,(r:any) => {
	    console.log(r)
	    if(r.winners !== undefined){
		this.setState({winners:r.winners})
	    }
	})
    }
    expand_object(e:any,oid:string){
	console.log(` expand obj ${oid}`)
	e.target.closest('.instance').classList.add('expanded')
	// TODO: maak een soort van object-view hierbinnen
    }
    render_winner(tup:any){
	if(tup.related === undefined){
	    return null
	}
	let r = tup.related
	let reltup = this.props.tup.tup
	// append the relation-factor
	let app = ""
	if(reltup.cft_rel_factor !== "no"){
	    app = " [ " + tup.value + " ]"
	}
	if(r === null){return null}
	return (
	    <div key={r.id} className="winner-with-btns">
		<FlexObjectRow tup={r}
	    context="relation"
	    append={app}
	    cb_parent={this.props.cb_parent}
	    cb_show_votes={this.cb_show_votes}
	    config={this.props.config} />
	    </div>
	)
    }
    click_show_history(){
	console.log('show history')
	this.props.cb_parent({
	    cmd: 'show-history',
	    label: 'history',
	    post_id: this.props.post_id,
	    aspect:'current',
	    acf_key: this.props.tup.key
	}, (e:any)=>{
	    console.log('return from cb-parent')
	})
	    
    }

    cb_show_votes(e:any){
	let winner = e.winner
	console.log('cb-show-votes')
	let tup = this.props.tup
	console.log('show votes , claims + add claim')
	console.log(winner)
	this.props.cb_parent({
	    label: 'Claims + add Claim: '+tup.label,
	    cmd: 'relation-vote',
	    post_id: this.props.post_id,
	    block_type: 'relation',
	    winner_id: winner.id,
	    relation: tup.tup,
	    aspect:'current',
	    with_history: true,
	    history_popup:{
		cmd: 'show-history',
		label: 'history',
		post_id: this.props.post_id,
		aspect:'current',
		acf_key: this.props.tup.key
	    } 
	}, (e:any)=>{
	    console.log('return from cb-parent')
	})
    }
    
    render_winners(){
	let list = this.state.winners

	return (
	    <div className="winners instances">
		{list.map((x:any) => this.render_winner(x))}
		</div>
	)
    }
    render(){
	let tup = this.props.tup

	let label = tup.ui_label
	if(label === null || label === ""){
	    label = tup.label
	}
	return (
    	    <div key={tup.tup.rid} className="input-field relation">
		<div className="label">{label}</div>
		{this.render_winners()}
		<div className="cft-small-icon cft-btn add-relation" onClick={this.on_click_add}>
		<img 
		    alt="" src={require('./img/plus-icon.png')} />
		</div>
	    </div>
	)
    }

}

export default RelationRow
