import * as React from 'react';

//import {advise_type, render_advise} from '../AdviseUtils'



import WpApi from '../wpapi'
import Loader from '../Loader'
import Loco from '../Loco'
import HrefLink from '../HrefLink'


interface MyProps {
    cb_parent:(e?: any) => void
}


type MyState = {
    aspect:string,
    post_id:string,
    uom:string,
    relobj:any,
    related_post_id:string,
    inverse_rel:boolean,
    choices:Array<any>,
    top3: Array<string>,
    winner:string,
    level2:string,
    loading:boolean,
    block_type:string,
    votetype:string,
    bouwdeel:string,
    is_final_field:boolean,
    compactview:boolean,
    acf_key:string,
    acf_key_step:string,
    acf_key_select:string,
    label1: string,
    editing_choice:boolean,
    editing_attr:any,
    attr_id: string,
    invalid_input:boolean,
    trigger_recalc:boolean,
    prepend:string,
    append:string,
    pattern:string

};

class HdaVoteGrid extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";

    private comment_textarea =  React.createRef<HTMLTextAreaElement>();
    private advise_price_input =  React.createRef<HTMLInputElement>();
    private advise_gain_input =  React.createRef<HTMLInputElement>();
    private number_input = React.createRef<HTMLInputElement>();
    private text_input = React.createRef<HTMLInputElement>();
    private advise_textarea =  React.createRef<HTMLTextAreaElement>();
    

    constructor(props: MyProps) {
        super(props)
	this.check_validity = this.check_validity.bind(this)
	this.handle_chosen_option = this.handle_chosen_option.bind(this)
	this.handle_trash_clicked = this.handle_trash_clicked.bind(this)
	this.init_choice = this.init_choice.bind(this)
	this.handle_save_comment = this.handle_save_comment.bind(this)

	this.handle_save_number = this.handle_save_number.bind(this)
	this.handle_save_text = this.handle_save_text.bind(this)
	this.handle_edit_btn_click = this.handle_edit_btn_click.bind(this)
	
	this.state = {
	    aspect:"",
	    post_id:"",
	    related_post_id:"",
	    inverse_rel:false,
	    relobj:null,
	    choices:[],
	    top3:[],
	    loading:true,
	    block_type: '',
	    votetype: 'choice',
	    bouwdeel:'',
	    uom:'',
	    winner:'',
	    level2:'',
	    is_final_field:false,
	    compactview:false,
	    acf_key_step:'',
	    acf_key_select:'',
	    acf_key:'',
	    attr_id:'',
	    pattern:'',
	    prepend:'',
	    append:'',
	    label1: Loco.tr('choose_preferred_option'),
	    editing_choice:false,
	    invalid_input:false,
	    trigger_recalc:false,
	    editing_attr:{}
	}
    }

    // radio-option clicked (can be a comment / number / choice / advise / .. )
    public handle_chosen_option(e:any){
	console.log("handle chosen_option")
	let val = e.target.getAttribute('value')
	// send signal to parent : the VoteGridModal
	let acfkey = this.state.acf_key_step
	if( acfkey === ""){
	    acfkey = this.state.acf_key
	}
	let d : {[k:string]:any} = {
	    //kind: 'chosen_option',
	    block_type:this.state.block_type,
	    attr_type:'',
	    post_id:this.state.post_id,
	    aspect: this.state.aspect,
	    is_final_field: this.state.is_final_field,
	    trigger_recalc:this.state.trigger_recalc,
	    cft_block: acfkey,
	}
	if(this.state.votetype === "text"){
	    d.attr_type = "choose_text"
	    d.choice_attr_id= val
	}
	else if(this.state.votetype === "number"){
	    d.attr_type = "choose_number"
	    d.choice_attr_id= val
	}
	else if(this.state.votetype === "choice"){
	    d.value = val
	    d.attr_type = "choose_option"
	}
	else if(this.state.votetype === "relation"){
	    d.attr_type = "add_relation"
	    d.target_1 = this.state.post_id
	    d.target_2 = this.state.related_post_id
	    d.inverse = this.state.inverse_rel
	    d.factor = val
	}
	this.props.cb_parent(d)

    }
    public handle_trash_clicked(a:any){
	console.log("remove vote..")
	console.log(a)
	this.props.cb_parent({
	    kind: 'remove_vote',
	    attr_id: a.id,
	    is_final_field: this.state.is_final_field,
	    
	})

    }
    handle_save_text(){
	console.log("save txt")
	if(this.state.invalid_input){
	    console.log('invalid input')
	    return;
	}
	let val = this.text_input.current!.value
	console.log(` val: ${val}`)
	if(val.trim() === ""){
	    console.log("value is empty")
	    return
	}
	if(this.state.editing_choice){
	    console.log('update text TODO')
	}else{
	    this.props.cb_parent({
		kind: 'add_text',
		aspect: this.state.aspect,
		val: val,
		acf_key:this.state.acf_key_step,
	    })
	}
	
    }
    handle_save_number(){
	console.log("save number")
	if(this.state.invalid_input){
	    console.log('invalid input')
	    return;
	}
	let val = this.number_input.current!.value
	if(val.trim() === ""){
	    console.log("value is empty")
	    return
	}
	console.log(` val: ${val}`)
	if(this.state.votetype === 'relation'){
	    let acfkey = this.state.acf_key
	    let d = {
		block_type:this.state.block_type,
		post_id:this.state.post_id,
		aspect: this.state.aspect,
		is_final_field: this.state.is_final_field,
		trigger_recalc:this.state.trigger_recalc,
		cft_block: acfkey,
		attr_type : "add_relation",
		target_1 : this.state.post_id,
		target_2 : this.state.related_post_id,
		inverse : this.state.inverse_rel,
		uom: this.state.uom,
		factor : val
	    }
	    this.props.cb_parent(d)
	
	}
	if(this.state.editing_choice){
	    console.log('update number')
	}else{
	    this.props.cb_parent({
		kind: 'add_number',
		aspect: this.state.aspect,
		val: val,
		acf_key:this.state.acf_key_step,
	    })
	}
    }
    handle_save_comment(){
	console.log("save comment")
	let el = this.comment_textarea.current!
	var d : {[key:string]:string} = {}
	if(this.state.editing_choice){
	    d = {
		kind: 'update_comment',
		votetype: 'update_comment',
		attr: this.state.editing_attr,
		level2: this.state.level2,
		comment: el.value
	    }
	    if(this.state.attr_id){
		d.on_attr_id = this.state.attr_id
	    }
	    this.props.cb_parent(d)
	    
	}else{
	    d = {
		kind: 'add_comment',
		post_id: this.state.post_id,
		level2: this.state.level2,
		txt: el.value
	    }
	    if(this.state.attr_id){
		d.on_attr_id = this.state.attr_id
		d.acf_key = this.state.acf_key
		d.aspect = this.state.aspect
	    }else{
		d.aspect = this.state.aspect
		d.acf_key = this.state.acf_key_step
		d.bouwdeel = this.state.bouwdeel
	    }
	    
	    this.props.cb_parent(d)
	}
    }
    public init(data:any){
	let post_id = data.post_id
	let post_id_num = Number(post_id)
	if(isNaN(post_id_num)){
	    console.log(` pickup post-id from e  ( ${data.post_id} )`)
	    post_id = data.post_id
	}
	this.setState({post_id:post_id},()=>{
	    if(data.cmd === "choice-vote"){
		this.init_choice(data)
	    }else if(data.cmd === "quotations-comment-vote"){
		this.init_quotations_comment(data)
	    }else if(data.cmd === "quotation-status-vote"){
		this.init_quotation_status(data)
	    }else if(data.cmd === "inputs-comment-vote"){
		this.init_inputs_comment(data)
	    }else if(data.cmd === "comment-vote"){
		this.init_comment(data)
	    }else if(data.cmd === "number-vote"){
		this.init_number(data)
	    }else if(data.cmd === "text-vote"){
		this.init_text(data)
	    }else if(data.cmd === "relation-vote"){
		this.init_relation(data)
	    }
	    
	})
    }
    public init_quotations_comment(data:any){
	console.log(data)
	console.log(`init_quotation_comment - ${data.attr_id}`)
	this.init_comment_on_attr(data)
    }
    public init_quotation_status(data:any){
	console.log(data)

	console.log(`init_quotation_status - ${data.attr_id}`)
	this.setState({
	    acf_key_select: data.acf_key_select,
	    acf_key_step: data.acf_key_select,
	    votetype: 'choice-on-attr',
	    aspect: data.aspect,
	    attr_id: data.attr_id,
	})
	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key_select: data.acf_key_select,
	    aspect: data.aspect,
	    on_attr_id: data.attr_id
	}

	WpApi.do_get('/choice-votes',d, (r) => {
	    this.got_value_choices(r)
	    this.setState({loading:false})
	})
	
    }
    public init_comment_on_attr(data:any){
	this.setState({
	    attr_id: data.attr_id,
	    votetype:'comment',
	    aspect:data.aspect,
	    acf_key:data.acf_key,
	    top3: data.top3.map((x:any)=>x.id)
	})
	data.on_attr_id = data.attr_id
	WpApi.do_get('/comment-votes', data,(r) => {
	    this.got_comment_choices(r)
	    this.setState({loading:false})
	})
    }
    public init_inputs_comment(data:any){
	console.log('inputs-comment-vote')
	console.log(data)
	this.setState({
	    attr_id: data.attr_id,
	    votetype:'comment',
	    acf_key_step: data.acfkey,
	    level2:'NOT_LEVEL2',
	    label1: Loco.tr('choose_preferred_comment'),
	    aspect: data.aspect
	})
	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key: data.acfkey,
	    level2:'NOT_LEVEL2',
	    aspect: data.aspect
	}
	if( data.attr_id){
	    d.on_attr_id = data.attr_id
	}
	
	WpApi.do_get('/comment-votes', d,(r) => {
	    this.got_comment_choices(r)
	    this.setState({loading:false})
	})
	
    }
    public init_comment(data:any){
	console.log(`init_comment - ${data.aspect}`)
	console.log(data)
	let lvl2 = data.level2 === undefined ? 'NOT_LEVEL2' : data.level2
	this.setState({
	    aspect:data.aspect,
	    acf_key_step: data.acf_key_step,
	    acf_key_select: data.acf_key_select,
	    votetype:'comment',
	    level2: lvl2,
	    bouwdeel: data.bouwdeel,
	    label1: Loco.tr('choose_preferred_comment'),
	    loading:true
	})

	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key: data.acf_key_step,
	    level2: lvl2,
	    aspect: data.aspect
	}
	if(data.bouwdeel !== undefined && data.bouwdeel !== null){
	    d.bouwdeel = data.bouwdeel
	}
	WpApi.do_get('/comment-votes', d,(r) => {
	    this.got_comment_choices(r)
	    this.setState({loading:false})
	})
	
    }
    /*
     *  given a numeric-relation, we show the proposed factors + allow to add your own factor
     */
    public init_relation(data:any){
	console.log('init relation')
	console.log(data)
	let rel = data.relation
	this.setState({
	    aspect:data.aspect,
	    acf_key: rel.rid,
	    related_post_id:data.winner_id,
	    block_type: 'relation',
	    pattern: '[0-9]+',
	    inverse_rel:rel.inverse,
	    label1: 'Relation',
	    votetype:'relation'
	})
	let d = {
	    post_id:data.post_id,
	    attr_type:'relation',
	    rid: rel.rid,
	    winner_id: data.winner_id,
	    aspect:'current',
	    inverse: rel.inverse
	}
	console.log(d)
	WpApi.do_get('/vote-choices',d, (r) => {
	    this.got_rel_choices(r)
	    this.setState({loading:false})
	})

    }
    public init_choice(data:any){
	console.log(`init_choice - ${data.aspect} cmd: ${data.cmd}`)
	console.log(data)
	let label1 = this.state.label1
	/* if(data.bouwdeel !== undefined && data.bouwdeel !== ""){
	    label1 = Loco.tr( 'choose_preferred_judgement')
	}*/
	// fetch existing votes
	this.setState({
	    aspect:data.aspect,
	    acf_key: data.acf_key,
	    label1: label1,
	    block_type:data.block_type,
	    votetype:'choice',
	    trigger_recalc: data.trigger_recalc,
	    bouwdeel: data.bouwdeel,
	    is_final_field: data.is_final_field,
	    loading:true
	})

	let d :{[name:string]:string}= {
	    post_id: this.state.post_id,
	    acf_key: data.acf_key,
	    attr_type: 'choice',
	    aspect: data.aspect,
	}
	if(data.bouwdeel !== undefined && data.bouwdeel !== null){
	    d.bouwdeel = data.bouwdeel
	}

	WpApi.do_get('/vote-choices',d, (r) => {
	    this.got_value_choices(r)
	    this.setState({loading:false})
	})
    }
    public init_text(data:any){
	console.log('init_text')
	console.log(data)
	this.setState({
	    votetype:'text',
	    aspect: data.aspect,
	    block_type:data.block_type,
	    pattern: data.pattern,
	    winner:data.winner,
	    acf_key: data.acf_key
	})
	WpApi.do_get('/vote-choices', {
	    post_id: this.state.post_id,
	    aspect: data.aspect,
	    attr_type: 'text',
	    acf_key: data.acf_key
	},(r) => {
	    this.got_text_choices(r)
	    this.setState({loading:false})
	})
	
    }
    init_number(data:any){
	console.log('init_number')
	console.log(data)
	this.setState({
	    votetype:'number',
	    block_type:data.block_type,
	    aspect: data.aspect,
	    pattern: data.pattern,
	    trigger_recalc: data.trigger_recalc,
	    winner:data.winner,
	    acf_key_step: data.acf_key
	})
	WpApi.do_get('/vote-choices', {
	    post_id: this.state.post_id,
	    aspect: data.aspect,
	    attr_type:'number',
	    acf_key: data.acf_key
	},(r) => {
	    this.got_number_choices(r)
	    this.setState({loading:false})
	})
    }
    got_text_choices(r:any){
	console.log(r)
	// prepare choices, add attributes to choices + flag which is 'winner' and 'mine' 
	var clist = []
	let winner_id = r.winner
	for(var a of r.attr){
	    let mycount = a.votes.filter((x:any)=> x.mine).length

	    clist.push({
		attr_id: a.id,
		value: a.id,
		label: a.value,
		mine: a.mine,
		votes: a.votes,
		my_choice: (mycount > 0),
		//is_top3: this.state.top3.includes(a.id),
		is_top3:false,
		is_winner: (a.id === winner_id)
	    })
	}
	this.setState({choices: clist, winner: winner_id, prepend: r.field.prepend, append: r.field.append})
	

    }
    get_uom(relobj:any, uom_id:string){
	for(var x of relobj.uoms){
	    if(x.id === Number(uom_id)){
		return x.title
	    }
	}
	return "-"+uom_id
    }
    got_rel_choices(r:any){
	var clist = []
	let winner_id = r.winner
	let relobj = r.relobj
	let relfact = r.relobj.tup.cft_rel_factor
	this.setState({  relobj: r.relobj })

	if(relfact === "no"){
	    let check = r.attr.filter((x:any)=> x.value === "-1")
	    if(check.length === 0){
		r.attr.push({
		    value:-1,
		    votes:[]
		})

	    }
	}
	// add default option Disgree
	//
	//
	for(var a of r.attr){
	    let mycount = a.votes.filter((x:any)=> x.mine).length
	    let label = a.value
	    if(relfact === "no"){
		if(a.value > 0){
		    label = "Agree"
		}else{
		    label = "Disagree"
		}
	    }
	    clist.push({
		attr_id: a.id,
		uom: this.get_uom(r.relobj,a.uom),
		value: a.value,
		label: label,
		mine: a.mine,
		votes: a.votes,
		my_choice: (mycount > 0),
		is_top3: this.state.top3.includes(a.id),
		is_winner: (a.id === winner_id)
	    })
	}
	this.setState({choices: clist, winner: winner_id})
    }
    render_uom_selector(ilist:Array<any>){
	if(ilist === undefined || ilist.length === 0){return}
	return (
	    <div>
		<select onChange={(e:any) => {this.setState({uom:e.target.value})}}>
	         <option value="">- kies UOM -</option>
		{ilist.map((tup:any) => ( <option key={tup.id} value={tup.id}> { tup.title } </option>))}
		</select>
	    </div>
	)
    }
    got_number_choices(r:any){
	console.log(r)
	var clist = []
	let winner_id = r.winner
	for(var a of r.attr){
	    let mycount = a.votes.filter((x:any)=> x.mine).length

	    clist.push({
		attr_id: a.id,
		value: a.id,
		label: a.value,
		mine: a.mine,
		votes: a.votes,
		my_choice: (mycount > 0),
		is_top3: this.state.top3.includes(a.id),
		is_winner: (a.id === winner_id)
	    })
	}
	this.setState({choices: clist, winner: winner_id, prepend: r.field.prepend, append: r.field.append})
	
    }
    
    public got_value_choices(r:any){
	console.log(r)
	// prepare choices, add attributes to choices + flag which is 'winner' and 'mine' 
	var clist = []
	
	for(let [val,lab] of Object.entries(r.field.choices)){
	    var alist = r.attr.filter((a:any) =>  a.value === val)
	    let mycount = alist.filter((x:any)=> x.mine).length
	    var tup = {
		value: val,
		label: lab,
		votes: alist,
		my_choice: (mycount > 0),
		is_winner: (val === r.winner)
	    }
	    if(val !== 'kies')  clist.push(tup)
	}
	console.log(clist)
	
	this.setState({choices: clist})
    }
    public got_comment_choices(r:any){
	console.log(r)
	// prepare choices, add attributes to choices + flag which is 'winner' and 'mine' 
	var clist = []

	for(var a of r.attr){
	    let mycount = a.votes.filter((x:any)=> x.mine).length
	    let othercount = a.votes.filter((x:any)=> !x.mine).length
	    let iswinner = this.state.attr_id === ""
		? (r.winner === a.id)
		: this.state.top3.includes(a.id)

	    clist.push({
		attr_id: a.id,
		value: a.id,
		label: a.value,
		mine: a.mine,
		votes: a.votes,
		my_choice: (mycount > 0),
		othercount:othercount,
		is_winner: iswinner
	    })
	}
	this.setState({choices: clist})
    }
    
    public render_vote(a:any,i:number){
	let name = a.mine ? 'jou' : a.display_name
	let params = 'attr_id='+a.id
	return (
	    <div className="vote" key={i}>
		<div className="label">Deze is gekozen op {a.date} door {name} [PT {a.profile_type} {a.ranking}] </div>
		<HrefLink params={params} page="vote" text="[..]" />
		{a.mine ? (
		    <span data-balloon="Verwijder keuze" data-balloon-pos="up" onClick={(e)=>{this.handle_trash_clicked(a) }} className="cf-icon-btn icon-large">
			<i className="bb-icon-l bb-icon-trash"/>
			</span>
		):''}

	    </div>)
    }
    /*
     *  Button click to edit 1 comment or advise choice.
     *   reusing the form to add a new comment or advise
     */
    handle_edit_btn_click(e:any,tup:any){
	console.log(e.target)
	this.setState({
	    editing_choice:true,
	    editing_attr:tup
	})
	console.log(tup)
	if(this.state.votetype === 'comment'){
	    let ta = this.comment_textarea.current!
	    ta.value = tup.label
	}
	
    }
    public render_choice(tup:any,i:number){
	var key = tup.value
	var win_mark = tup.is_winner ? '*' :''
	var t3_mark = tup.is_top3 ? '*':''
	let edit_btn = this.state.votetype === "number" ? null : (
	    <span className="cf-icon-btn"
	    data-balloon="Bewerk" data-balloon-pos="up"
	    onClick={(e:any)=>{this.handle_edit_btn_click(e,tup)}}><i className="bb-icon-l bb-icon-edit"/></span>
	)
	if(tup.othercount !== undefined && tup.othercount > 0){
	    let txt = "Niet meer aanpasbaar"
	    edit_btn = (
		<span className="cf-icon-btn disabled" data-balloon={txt} data-balloon-pos="up">
		    <i className="bb-icon-l bb-icon-edit"/></span>
	    )
	}
	let tuplabel = tup.label
	let uom = tup.uom === undefined ? "" : tup.uom

	return (
	    <div className="choice-folder" key={key}>
	    <div className="choice" >
		<input name="val" type="radio" defaultChecked={tup.my_choice}
	            id={key} value={key} onClick={this.handle_chosen_option}/>
		<label className="label" htmlFor={key}> {tuplabel} {uom} {t3_mark}{win_mark}  </label>
		{tup.mine ? edit_btn : null}
	    </div>	    
		<div className="votes">
		  {tup.votes.map((a:any,i:number) => this.render_vote(a,i))}
	    </div>
		</div>

	)
    }
    check_validity(e:any) {
	console.log("change")
	if (e.target.checkValidity()) {
	    this.setState({invalid_input:false})
	    e.target.parentElement!.classList.remove('invalid')
	} else {
	    e.target.parentElement!.classList.add('invalid')
	    this.setState({invalid_input:true})
	}
    }
    
    render_footer(){
	let choice_count = Object.entries(this.state.choices).length

	if(this.state.votetype === 'comment'){
	    let lbl = choice_count > 0 ?  Loco.tr('or_write_your_comment') :  Loco.tr('write_your_comment')
	    return (
		<div className="footer">		
		    <div className="formfields add-comment">
			
			{this.state.editing_choice ? Loco.tr('change_comment_and_save')  : lbl }
			<textarea ref={this.comment_textarea} placeholder={Loco.tr('write_your_comment_here')}></textarea>
			<span onClick={this.handle_save_comment}
			      data-balloon="Opslaan" data-balloon-pos="up"
			      className="cf-icon-btn larger"><i className="bb-icon-l bb-icon-save"/></span>
	            </div>
		    {this.state.attr_id !== "" ? <div className="explain"></div> : null}
		</div>
	    )
	}else if(this.state.votetype === 'choice'){
	    return (
		<div className="footer">
		    <div className="explain"></div>
		</div>
	    )
	}else if(this.state.votetype === 'text'){
	    let lbl = choice_count > 0 ? Loco.tr('or_add_your_text') : Loco.tr('add_your_text')
	    return (
		<div className="footer">
		    {lbl}
		    <div className="text-input">
		    <span>{this.state.prepend} </span>
		    <input ref={this.text_input}  onChange={this.check_validity} />
		    <span> {this.state.append}</span>
		    </div>
		    <span onClick={this.handle_save_text}
			      data-balloon="Opslaan" data-balloon-pos="up"
			      className="cf-icon-btn larger"><i className="bb-icon-l bb-icon-save"/></span>
		    <div className="explain">
		     <div>(**) {Loco.tr('explain_winner_txt')}</div>
		    <div> (*) {Loco.tr('explain_top_3_txt')} </div>
		</div>
		</div>
	    )
	}else if(this.state.votetype === 'number' || 
		this.state.votetype === 'relation'){
	    let relobj = this.state.relobj
	    let lbl = choice_count > 0 ? Loco.tr('or_add_your_number') : Loco.tr('add_your_number')
	    return (
		<div className="footer">
		    {lbl}
		    <div className="number-input">
		    <span>{this.state.prepend} </span>
		    <input ref={this.number_input} pattern={this.state.pattern} onChange={this.check_validity} />
		    <span> {this.state.append}</span>
		    {relobj === null ? null : this.render_uom_selector(relobj.uoms)}
		    </div>
		<div className="input-with-btn cft-small-icon">
		    <img 
			src={require("../img/save-icon-floppy.jpg")}
			alt=""
			onClick={this.handle_save_number} />
			</div>
		    <div className="explain">
		     <div>(**) {Loco.tr('explain_winner_txt')}</div>
		    <div> (*) {Loco.tr('explain_top_3_txt')} </div>
		</div>
		    </div>
	    )
	}
	
	return  (<div></div>)
    }

    public render(){
	let count = Object.entries(this.state.choices).length
	var table = (
	    <div className="choices-table">
		<div className="choice-header">
		    { count > 0 ? (  <div>{this.state.label1}</div> ) : null}
		</div>
		{ this.state.choices.map((tup:any,i:number) => this.render_choice(tup,i))}
	    </div>
	)
	return (
	    <div className={"vote-grid "+this.state.votetype} >
	      {this.state.loading ? <Loader /> : null}
	      {this.state.editing_choice ? null : table}
	      {this.render_footer()}
  	    </div>
		
	);

    }
}
export default HdaVoteGrid;
