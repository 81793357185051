import * as React from 'react'
import WpApi from './wpapi'
import $ from 'jquery'

//import {Instances_mode} from './InstancesListMode'
import TimelineRow from './TimelineRow'


export type Instances_mode = "timeversion" | "navigation" | "products";
/*
 *  mode:
 *     timeversion ; purpose (for props.cb) is for timeline-types to return a timeversion OR timeline
 *                     fixme: distinguish between timeversion / timeline / both
 *     navigate    ; purpose is  navigation, to go to ObjectView. We
 *                   dont show timeversions, these can be found via ObjectView of timeline
 */
interface MyProps {
    object_type:any
    cb:(e:any)=>void
    showheader:boolean
    filter:{[k:string]:string}
    mode: Instances_mode
}
type MyState = {
    new_item_name:string
    instances:Array<any>
    header:any,
    descen:Array<any>
    object_type:any
    msg:string
    filter:any
}

class InstancesList extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	
    constructor(props: MyProps) {
        super(props)
	
	this.on_key_up = this.on_key_up.bind(this)
	this.on_click_header_item = this.on_click_header_item.bind(this)

	this.state = {
	    new_item_name:'',
	    instances:[],
	    descen:[],
	    filter:null,
	    header:null,
	    msg:'',
	    object_type:null
	    

	}
    }
    /*
    componentDidUpdate(prevProps:any) {
	if (prevProps.object_type !== this.props.object_type) {
	    console.log('updated obj.type prop')
	}
    }
*/
    fetch_instances(otname:string){
	let path = '/objects'
	let filter_s = JSON.stringify(this.props.filter)
	let d = {
	    mode: 'get-rich-instances',
	    filter: filter_s,
	    obtype: otname
	}
	WpApi.do_get(path, d,(r) => {
            console.log(r)
            this.setState({
		instances: r.list,
		header:r.header,
	    })
	})	
    }
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	this.setState({object_type:this.props.object_type,filter:this.props.filter})
	let ot = this.props.object_type
	if(ot.name === undefined){
	    return
	}
	if(!ot.is_abstract){
	    this.fetch_instances(ot.name)
	}
	let d = {
	    mode: 'get-object-type-desc',
	    obtype: ot.name
	}
	this.fetchPromise = WpApi.do_get('/objects', d,(r) => {
            console.log(r)
	    this.setState({descen:r.descen})
	})
    }
    
    
    on_key_up(e:any){
	if(e.keyCode === 13){
	    // Enter key was pressed
		console.log('submit')
		if(this.state.object_type.is_abstract){
		    let m = 'cannot create an instance of an abstract object-type'
		    console.log(m)
		    this.setState({msg:m})
		    return
		}
	    let path = '/objects'
	    let d = {
		cmd: 'create_instance',
		obtype:this.state.object_type.name,
		name: this.state.new_item_name
	    }
	    console.log(d)
	    WpApi.do_post(path, d,(r) => {
		console.log(r)
		if(this.props.mode === "navigation"){
		    window.location.reload()
		}else{
		    if(this.props.mode === "timeversion" && r.instance.metatype === "timeline"){

			// append to list
			let prev = this.state.instances
			this.setState({
			    msg:'Please select a timeversion',
			    instances: [...prev, r.instance]
			})
		    }else{
			// append to list
			let prev = this.state.instances
			r.instance.blocks = {}
			this.setState({
			    instances: [r.instance,  ...prev ]
			})

		    }
		}
	    })
	}
    }
    


    render_create_instance_forms(){
	return (
	    <div className="forms">
		{this.state.descen.map((ot:any) => this.render_create_instance_form(ot))}
	    </div>
	)
    }
    render_create_instance_form(ot:any){
	if(this.props.mode === "products"){return null}
	if(ot.name !== this.state.object_type.name){
	    return null
	}
	let label = ot.label
	return (
	    <div className="create-form" key={ot.name}>
		<div> <span> Create a new {label}: </span>
		<input placeholder="- type name -" value={this.state.new_item_name}
	            onKeyUp={this.on_key_up}
		       onChange={(e:any)=>{ this.setState({new_item_name:e.target.value})}}
		    />
		</div>
	      <b> or select below </b>
	    </div>
	)
    }
    render_inst_block(block_id:string, tup:any){
	let bval = tup.blocks[block_id]
	let hdr = this.state.header
	let blk = hdr.blocks[block_id]
    	let val = ''
	if(blk.block_type === 'relation'){
	    val = bval.title
	}else{
	    val = bval
	}
	return (
	    <div className={block_id} key={block_id}> {val} </div>
	)
    }
    on_change_filter_input(block_id:string,value:string){
	console.log(`filter-change ${block_id} ${value}`)
	$('.instance-block-body').removeClass('verberg')
	let filters : {[k:string]: string } = {}
        document.querySelectorAll('.block-grid-header input').forEach((e)=>{
            let k = $(e).attr('name')
            if(k !== undefined){
                let v = String( $(e).val()).toLowerCase()
                if(v){
                    filters[k] = v
                }
            }
        });
	console.log(filters)
	// iterate rows
	document.querySelectorAll('.instance-block-body').forEach((e) => {
            Object.entries(filters).map(([name,val]:[string,string]) =>{
                let child = e.querySelector(`.${name}`)
                if(child){
                    let text = child.textContent ? child.textContent : ''
                    console.log(` text ${text}`)
                    if(!text.toLowerCase().includes(val)){
			console.log('notincludes')
                        e.classList.add('verberg')
                    }
                }
            })
        });



    }
    on_click_header_item(e:any){
	 if(e.target.nodeName == 'INPUT'){
            return;
        }
	let trg = e.target.closest('.header-item')
        let name = trg.classList[0]
        console.log(` col-header clicked name: ${name}`)

        let cur = 'none'
        
        if(trg.classList.contains('sorting-d')){
            cur = 'sorting-d'
        }else{
            if(trg.classList.contains('sorting-a')){
                cur = 'sorting-a'
            }
        }
        $('.block-grid-header .header-item').removeClass('sorting-a');
        $('.block-grid-header .header-item').removeClass('sorting-d');

        console.log(`current mode: ${cur}`)


        if(cur === 'sorting-a' || cur === 'none'){
            console.log('set sorting-d')
            trg.classList.add('sorting-d')
            this.sort_rows(name,true)
        }else{
            console.log('set sorting-a')
            trg.classList.add('sorting-a')
            this.sort_rows(name,false)
        }


    }
    sort_rows(name:string, rev:boolean){
	console.log(` sorting ${name} ${rev}`)
	let body = '.instances > .body'
	//@ts-ignore	
	$(body + ' > .bl-instance').sort((a:HTMLElement,b:HTMLElement)=>{
	    let ta = $(a).find(`.row > .${name}`).text().toLowerCase();
            let tb = $(b).find(`.row > .${name}`).text().toLowerCase();
	    //console.log(` ${name} : ${ta} `)
            let ret = (ta > tb ? 1 : (ta < tb ? -1 : 0));
            if(rev){
                return ret * -1;
            }
            return ret
	}).appendTo(body)

    }
    render_header_item(block_id:any){
	let hdr = this.state.header
	let blk = hdr.blocks[block_id]
	return (
	    <div className={block_id+" header-item"} key={block_id} onClick={this.on_click_header_item}> 
	      <div className="label">{blk.label}</div>
	      <input name={block_id} onChange={(e:any)=>this.on_change_filter_input(block_id,e.target.value)}/>
	    </div>
	)
    }
    render_header(){
	let hdr = this.state.header
	if(hdr === undefined || hdr === null ){
	    return null
	}
	if(hdr.cft_blocks === undefined){
	    return null
	}
	let n = hdr.cft_blocks.length
	let cl = "n-"+n
	return (
	    <div className={"block-grid-header "+cl}>
	    {hdr.cft_blocks.map((x:string)=>this.render_header_item(x))}
		</div>
	)
    }
    render_instance_blocks(tup:any){
	let hdr = this.state.header
	let n = hdr.cft_blocks.length
	let cl = "n-"+n
	return (
	    <div className="instance bl-instance" key={tup.id}
		 onClick={(e:any)=>this.props.cb(tup)}>
		<div className={"instance-block-body row "+cl}>
		 {hdr.cft_blocks.map((x:string) => this.render_inst_block(x,tup))}
		</div>
		<div className="cft-small-icon">
		<img src={require("./img/open-link-icon-11.png")} alt="" />
		</div>
	    </div>
	)
    }
    render_instance(tup:any){
	if(this.props.mode === "timeversion" && tup.metatype === "timeline"){
	    return (
		<TimelineRow key={tup.id} tup={tup} cb={this.props.cb}
		  header={this.state.header}/>
	    )
	}
	let block_count = Object.keys(tup.blocks).length
	if(block_count > 0){
	   return this.render_instance_blocks(tup)
	}
	let ot = tup.object_type
	if(ot.name !== this.state.object_type.name){
	    return null
	}
	/*
	if(!tup.title.includes(this.state.filter_txt)){
	    return null
	}
	*/
	return (
	    <div className="instance" key={tup.id}
		 onClick={(e:any)=>this.props.cb(tup)}>
		<div className="instance-body">
		    <div className="title">{ot.label} : {tup.title}</div>
		    <div className="date">{tup.date}</div>
		</div>
		<div className="cft-small-icon">
		<img src={require("./img/open-link-icon-11.png")} alt="" />
		</div>
	    </div>
	)
    }
    render_list(){
	let list = this.state.instances
	if(list.length === 0){
	    return null
	}
	return (
	    <div className="instances">
		{this.render_header()}
		<div className="body">
		{list.map((tup:any,i:number) => this.render_instance(tup))}
		</div>
	    </div>
	)
    }
    on_select_ot(tup:any){
	this.setState({object_type:tup})
	    this.fetch_instances(tup.name)
	//
	//fetch
    }
    render_ot_selector(){
	if(this.props.mode === "products"){return null}
	let descen = this.state.descen
	if(descen === undefined){return null}
	let list = descen.filter((x:any) => {return ! x.is_abstract})
	let current_ot = ''
	if(this.state.object_type !== null){
	    current_ot = this.state.object_type.name
	}
	return (
	    <div className="ot-selector">
		{list.map((tup:any)=>{
		    return (<div key={tup.name}>
			<input
			    checked={current_ot === tup.name}
			    type="radio"
			    name="obtype"
			    id={tup.name}
			    onChange={()=>{ this.on_select_ot(tup)}}/>
			<label htmlFor={tup.name}> {tup.label} </label>
			</div>
			)
		})}
		</div>
	)
    }
    render_filters(){
	return (
	    <div className="filters">
		</div>
	)
    }
    render(){
	let ot = this.props.object_type
	return (
	    <div className="instances-list">
		<div className="msg">{this.state.msg} </div>
		{this.props.showheader ? (<h3>Object-type: {ot.label}</h3>):null}
	    {this.render_ot_selector()}
	    {this.render_create_instance_forms()}
	    {this.render_filters()}
	    {this.render_list()}
	    </div>
	)
    }
}

export default InstancesList

