import FeatureInputRow from './FeatureInputRow'
import * as React from 'react'
import RelationRow from './RelationRow'
import Expander_helper  from './Expander_helper'
import HrefLink from './HrefLink'
import FlexObjectRow from './FlexObjectRow'
import Loco from './Loco'
import InputAddTimeversion from './InputAddTimeversion'
import ObjectStatus from './ObjectStatus'
import get_nice_label from './ObjectNiceName'

interface MyProps {
    tup:any
    instance:any
    config:any
    context:string
    post_id:number
    cb_parent:   (e: any, cb: (r: any) => void) => void
    cft_blocks:Array<any>
}
type MyState = {
    new_timeversions:Array<any>
    label:string
    instance_title:string
    warning:string
}
class CftBlockCluster  extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_cb_corefeature = this.handle_cb_corefeature.bind(this)

	this.state = {
	    new_timeversions:[],
	    warning:'',
	    instance_title:'',
	    label:''
	}
    }
    componentDidMount(){
	let tup = this.props.tup
	if(typeof tup.title === "string"){
	    this.setState({ label: tup.title})
	}
	let inst = this.props.instance
	if(inst.title !== undefined){
	    this.setState({instance_title: inst.title})
	}
    }
    get_block_by_key(key:string){
	let list = this.props.cft_blocks
	let list0 = list.filter((x:any) => x.key === key)
	if(list0.length > 0){
	    return list0[0]
	}
	return null
    }
    render_corefeature(ftup:any){
	let pid = this.props.post_id
	let default_val = ""
	if(ftup.name === "name"){
	    let inst = this.props.instance
	    default_val = inst.title
	}
	return (
	    <FeatureInputRow key={ftup.name} tup={ftup} post_id={Number(pid)}
	    default_value={default_val}
	    cb_parent={this.handle_cb_corefeature}
		/>
	)

    }
    handle_cb_corefeature(e:any,cb:(r:any)=>void){
	console.log(e)
	if(e.cmd === 'update-new-txt-winner'){
	    if(e.cftblockname === "name"){
    		this.setState({instance_title: e.winner_txt})
	    }
	}
	this.props.cb_parent(e,cb)
    }
    render_obj_feature(ftup:any){
	let pid = this.props.post_id
	return (
	    <FeatureInputRow tup={ftup} key={ftup.key} post_id={Number(pid)}
	    cb_parent={this.props.cb_parent}
		/>
	)
    }
    render_relation(tup:any){
	let pid = this.props.post_id
	return (
	    <RelationRow instance={this.props.instance}
	    config={this.props.config}
	    key={tup.key} tup={tup} post_id={Number(pid)}
	    cb_parent={this.props.cb_parent}
		/>
	)	
    }

    render_cft_block(block_id:string){
	let tup = this.get_block_by_key(block_id)
	if(tup === null){return }
	let row = null
	if(tup.block_type === "corefeature"){
	    row = this.render_corefeature(tup)
	}else if(tup.block_type === "objectfeature"){
	    row = this.render_obj_feature(tup)
	}else if(tup.block_type === "derived"){
	    row = this.render_derived(tup)
	}else if(tup.block_type === "relation"){
	    row = this.render_relation(tup)
	}
	return (
	    <div className="cft-block" key={block_id}>
	    {row}
	    </div>
	)
    }
    render_derived(tup:any){
	let v = ""
	if(tup.value !== null && tup.value !== undefined){
	    if(tup.value.value !== undefined){
		v = tup.value.value
	    }
	}
	return (
	    <div>
	       <div> {tup.label}
	        : {v}</div>
	    </div>
	)
    }
    render_cft_blocks(){
	let tup = this.props.tup
	if(tup.built_in === "timeversions"){
	    return this.render_timeversions()
	}
	if(tup.cft_blocks === undefined){return}
	if(typeof tup.cft_blocks !== "object") {return}
	return (
	    <div>
		{tup.cft_blocks.map((block_id:string) => this.render_cft_block(block_id))}
	    </div>
	)
    }
    render_timeversion_txt(inst:any){
	if(inst.metatype === "year-version"){
	    return (<div>Year:{inst.year}</div>)
	}
	if(inst.metatype === "timeperiod-version"){
	    return (<div>Time-period: {inst.period} </div>)
	}
	
    }
    render_hyperlink(){
	return null
	let inst = this.props.instance
	let params = 'obtype='+inst.obtype+'&instance='+inst.id
	if(inst.bbgrouplink !== undefined){
	    let url = inst.bbgrouplink + '?instance='+inst.id
		return (
		    <HrefLink page="org-dash" params={url} />
		)
	}
	if(this.props.context === "relation"){
	    return (
		<HrefLink page="obj-dash" params={params}  />
	    )
	}
	return null
    }

    render_link_to_timeline() {
	let mt = this.props.instance.metatype
	if(mt === "timeline" || mt === "timeless"){return null}
	let p = new URLSearchParams(window.location.search)
	let tup = this.props.instance
	let pid = tup.parent.id
	if(p.get('instance') !== null){

	    if(p.get('instance') === pid.toString()){
		return null
	    }
	}
	let url = tup.bbgrouplink + '?instance='+pid

	/*return (
	    <div className="cft-block timeline-parent">
		<div className="item" onClick={(e:any)=>{
		    e.preventDefault();this.props.cb_parent({"cmd":"navigate-to-object-id","object_id":pid},()=>{})
		}}> <a href={url}>&gt; view year-by-year</a></div>
	    </div>
	)
	*/
	let inst = this.props.instance
	let view = this.props.config.view
	if(inst.bbgrouplink !== undefined){
	    let url = inst.bbgrouplink + '?instance='+pid
		return (
	    <div className="cft-block timeline-parent">
		    <HrefLink page="org-dash" params={url} text="Goto timeline" with_icon="linkout" />
		    </div>
		)
	}
	let params = 'obtype='+inst.obtype+'&instance='+pid

       return (
	    <div className="cft-block timeline-parent">
	    <HrefLink page={view} text="Goto timeline" params={params}  with_icon="linkout" />
	</div>
       )
    }


    render_header_fixed(){
	let ctx = this.props.context
	let inst = this.props.instance
	//let nice_label = get_nice_label(inst, this.state.instance_title)
	return (
	    <div className="header-fixed">
		{this.render_hyperlink()}
		<ObjectStatus tup={inst} />
		{this.render_link_to_timeline()}
	    </div>
	)
    }
    render_new_timeversions(){
	let list = this.state.new_timeversions
	return (
	    <div className="new-timeversions">
		{list.map((tv:any) => this.render_timeversion(tv))}
	    </div>
	)
    }
    render_timeversion(tup:any){
	let mode = undefined
	//
	if(this.props.instance.metatype === "timeline"){
	    mode="timeversion-as-label"
	}
	return (
	    <FlexObjectRow key={tup.id} config={this.props.config}
	    context="timeversion"
	    tup={tup} cb_parent={this.props.cb_parent} mode={mode} />
	)
    }
    render_timeversions(){

	let tup = this.props.instance
	let list = tup.timeversions
	if(list === undefined){
	    return
	}
	
	return (
	    <div className="timeversions instances">
		{Object.entries(list).map(([_k,x]:[string,any])=> this.render_timeversion(x))}
		{this.render_new_timeversions()}
	        <InputAddTimeversion
		    tup={this.props.instance}
	            cb={(tv:any) => {
			let prev = this.state.new_timeversions
			this.setState({new_timeversions: [...prev, tv]})
		    }}
		/>
	    </div>
	)
	
    }
    render(){
	let tup = this.props.tup
	let exp = new Expander_helper()

	let cls = "cluster with-expand"
	if(tup.title !== "header"){
	    cls += " collapsed"
	}

	if(tup.built_in === "header"){
	    return (
		<div className="cluster header">
		    <div className="body">
			{this.render_header_fixed()}
			{this.render_cft_blocks()}
		    </div>
		</div>
	    )
	}

	let title = tup.title
	if(tup.built_in === "timeversions"){
	    title = Loco.tr('Year by year')
	}

	return (
	    <div className={cls}>
		<div className="header" onClick={exp.toggle_expand}>
		  <div className="pre-icon"></div>
		  <div className="label">{title} </div>
	        </div>

		<div className="body">
		{this.render_cft_blocks()}
		</div>
	    </div>
	    
	)
	//
    }
}
export default CftBlockCluster 
