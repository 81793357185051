import * as React from 'react'
import WpApi from './wpapi'
import MyConf from './config'
import InstancesList from './InstancesList'
import {Instances_mode} from './InstancesList'

interface MyProps {
    group_id:string
}
type MyState = {
    organisation:any
    object_types:any
}

class ProductList extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>

    constructor(props: MyProps) {
        super(props)
	this.state = {
	    organisation:null,
	    object_types:null
	}
    }

    componentDidMount(){
	if(this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	let conf = MyConf.params
	console.log(conf)
	let path = '/group-content'
	let d : {[k:string]:string}= {
	    mode: 'get-org-object',
	    with_object_type: 'owned_product',
	    group_id: this.props.group_id
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    console.log('got r')
	    if(r.org !== undefined){
		this.setState({
		    organisation: r.org,
		    object_types: r.object_types
		})
	    }
	})
    }
    on_chosen_instance(e:any){
	let conf = MyConf.params
	let url = e.bbgrouplink + '?instance=' + e.id
	console.log(url)
	if(conf.deploymode === "develop"){
	    url = '/obj_dash.html?obtype=owned_product&instance='+e.id
	}
	window.location.href = url

    }
    render_list(){
	if(this.state.organisation == null){
	    return null
	}
	let org = this.state.organisation
	let filter = { 
	    org_timeline_id: org.id
	}
	let ots = this.state.object_types

	return (

		<InstancesList
	    showheader={false}
	    filter={filter}
		    object_type={ots.owned_product}
		    mode={"products"}
		    cb={this.on_chosen_instance}
		/>

	)


    }
    render(){
	let gid = this.props.group_id
	return (
	    <div className="productlist">
	       {this.render_list()}
	    </div>
	)
    }
}
export default ProductList
