
class MyConf{
    public static params:any = {}
    
    get(){

	return {
	    //wp_api_host: 'https://market-13.dev.c-f.tools/',
	    wp_api_host: 'https://michiel-3.dev.c-f.tools/',
	    wp_api_path: '/wp-json/cft-gm-index/v1',
	    open_in_new_tab: false,
	    params: MyConf.params
	}
    }
	
}
export default MyConf





