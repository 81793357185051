import * as React from 'react'
import WpApi from './wpapi'
import UIAdminObjectType from './UIAdminObjectType'
interface MyProps {

}
type MyState = {
    object_types:any
    object_type:string
    for_timeline:boolean
}
class UIAdminDash extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>

 //   private upload_file =  React.createRef<HTMLInputElement>();

    constructor(props: MyProps) {
        super(props)

	
	this.on_chosen_otype = this.on_chosen_otype.bind(this)
	this.pop_state = this.pop_state.bind(this)
	//this.on_click_import = this.on_click_import.bind(this)


	
	this.state = {
	    object_type:'',
	    for_timeline:false,
	    object_types: null
	}
    }

    // user clicked 'Back'
    pop_state(e:any){
        let p = new URLSearchParams(window.location.search)
        if(p.get('object_type') === null){
            this.setState({object_type:''})
        }
        e.preventDefault()

    }

    componentDidMount(){
	window.addEventListener("popstate", this.pop_state)

	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}

	let path = '/objects'
	let d : {[k:string]:string}= {
	    mode: 'get-options'
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState( { object_types: r.options},
			   this.set_state_objtype_from_url)
	})
    }
    on_click_export(){
	let path = '/cft-blocks/admin'
	let d = {
	    mode: 'export-json'
	}
       WpApi.do_get(path,d,(r)=>{
	   console.log(r)
	   var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(r));
	   var elm = document.getElementById('downloadAnchorElem');
	   if(elm !== null){
	       elm.setAttribute("href",  dataStr);
	       elm.setAttribute("download", "cft_blocks_config.json");
	       elm.click();
	    }
       })
    }
    
    set_state_objtype_from_url(){
	let p = new URLSearchParams(window.location.search)
	let ot = p.get('obtype')
	let tl = p.get('timeline')
	let timeline = tl === null ? false : true

	if(ot !== null){
	    this.setState( {
		object_type:  ot,
		for_timeline: timeline
	    })
	}
    }

    on_chosen_otype(ot:string){
	console.log(`chosen otype ${ot}`)
	const url = new URL(window.location.href)
	url.searchParams.set("obtype",ot)
	window.history.pushState({obtype:ot},"",url)
	this.setState({ object_type: ot})
    }
    
    render_listitem(k:string,tup:any){
	return (
	    <div key={k} onClick={()=>{this.on_chosen_otype(k)}}>  {tup.label}</div>
	)
    }
    render_import_export(){
	return (
	    <div className="import-export">
		<div > Use the button below to export the Cft-block-cluster configuration from this WP-install as a JSON file.
		      You can import this JSON file (e.g. on another WP-install) via the 
		      <a href="/wp-admin/admin.php?page=cft_admin_page&view=block_clusters"> Cft-admin </a></div>
	       <button onClick={this.on_click_export}>Export config</button>
	     </div>
	)
    }
    render_list(){
	if(this.state.object_type !== ''){return null}
	if(this.state.object_types === null){
	    return
	}
	let items = Object.entries(this.state.object_types).sort((x:any,y:any)=>{
	    if(x[1].label >= y[1].label){
		return 1
	    }else{
		return -1
	    }
	})
	return (<div> 
		{this.render_import_export()}
	    <a href="" style={{display:"none"}} id="downloadAnchorElem">down</a>
		{items.map(([k,tup]:[string,any]) => this.render_listitem(k,tup))}

		</div>)
    }
    render_objtype(){
	let k = this.state.object_type
	if(k === ''){return null}
	let tup = this.state.object_types[k]
	return (
	    <div> chosen: {k}
		<UIAdminObjectType tup={tup} for_timeline={this.state.for_timeline} />
	    </div>
	)
    }
    render(){
	return (
	    <div>
		{this.render_list()}
	    {this.render_objtype()}
		</div>
	)
    }
}

export default UIAdminDash
