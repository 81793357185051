import * as React from 'react'

import WpApi from './wpapi'

interface MyProps {
    tup:any
}


type MyState = {
    editmode:boolean
    obstatus:string
}

class ObjectStatus  extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    constructor(props: MyProps) {
        super(props)
	this.on_change_select_status = this.on_change_select_status.bind(this)
	this.state = {
	    editmode:false,
	    obstatus:''
	}
    }
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	this.setState({ obstatus: this.props.tup.object_status})
    }
    capitalizeFirstLetter(str:string) {
	if(str.length < 3){return str}
	return str[0].toUpperCase() + str.slice(1);
    }
    on_change_select_status(e:any){
	let val = e.target.value
	console.log(`change status to ${val}`)
	this.post_status(val)
    }
    post_status(val:string){
	let d = {
	    cmd: 'change-object-status',
	    object_id: this.props.tup.id,
	    status:val,
	}
	console.log(d)
	WpApi.do_post('/objects',d,(r)=>{
	    console.log(r)
	    if(r.status === 'ok'){
		this.setState({
		    editmode:false,
		    obstatus:r.object_status
		})
	    }
	})
    }
    render_editmode(){
	let ostat = this.state.obstatus
	let lab = this.capitalizeFirstLetter(ostat)
	let options = [
	    {val: 'draft' , label: 'Draft' },
	    {val: 'pending', label: 'Pending'},
	    {val: 'published', label: 'Published'},
	];

	return (
	    <div> 
	    Current Status: {lab} 
	    <div> 
	    <select onChange={this.on_change_select_status}> 
	       <option>-kies-</option>
	       {options.map((x:any) => <option value={x.val} id={x.val}>{x.label}</option>)}
	       </select>
	 
	      </div>
	    Or <button onClick={()=>{this.setState({editmode:false})}}> cancel </button>
	      
	    </div>
	)

    }
    render(){
	let v = this.state.obstatus
	let lab = this.capitalizeFirstLetter(v)
	if(this.state.editmode){
	    return this.render_editmode()
	}
	return (
	    <div className="cft-block">
	    <div className="cft-object-status" onClick={()=>{this.setState({editmode:true})}}>Status: {lab} </div>
	    </div>
	)
    }
}
export default ObjectStatus
