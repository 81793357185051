import * as React from 'react'
import InputAddTimeversion from './InputAddTimeversion'
interface MyProps {
    tup:any
    header:any
    cb:(e:any)=>void
}
type MyState = {
    new_timeversions:Array<any>
}
class TimelineRow extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)

	this.state = {
	    new_timeversions: []
	}
    }
    get_year_or_period(tup:any){
	if(tup.metatype === "year-version"){
	    return tup.year
	}else if(tup.metatype === "timeperiod-version"){
	    return tup.period
	}
    }
    render_inst_block(block_id:string, tup:any){
	let bval = tup.blocks[block_id]
	let hdr = this.props.header
	let blk = hdr.blocks[block_id]
    	let val = ''
	if(blk.block_type === 'relation'){
	    val = bval.title
	}else{
	    val = bval
	}
	return (
	    <div className={block_id} key={block_id}> {val} </div>
	)
    }
    /*
     * render an instance as table-columns as defined in 'header' cluster
     */
    render_instance_blocks(tup:any){
	let hdr = this.props.header
	let n = hdr.cft_blocks.length
	let cl = "n-"+n
	return (
	    <div className="instance bl-instance" key={tup.id}
		 onClick={(e:any)=>this.props.cb(tup)}>
		<div className={"instance-block-body "+cl}>
		 {hdr.cft_blocks.map((x:string) => this.render_inst_block(x,tup))}
		</div>
		<div className="cft-small-icon">
		<img src={require("./img/open-link-icon-11.png")} alt="" />
		</div>
	    </div>
	)
    }
    /*
     * render an instance in simple-mode, in case the 'header' cluster is still empty
     */
    render_instance(tup:any){
	let ot = tup.object_type
	let block_count = 0
	if(tup.blocks !== undefined){
	    block_count = Object.keys(tup.blocks).length
	}
	if(block_count > 0){
	   return this.render_instance_blocks(tup)
	}
	return (
	    <div className="instance" key={tup.id}
		 onClick={(e:any)=>this.props.cb(tup)}>
		<div className="instance-body">
		    <div className="title">{ot.label} : {tup.title}</div>
		    <div className="date">{this.get_year_or_period(tup)}</div>
		</div>

	    </div>
	)
    }
    render_new_timeversions(tup_id:number){
	let list = this.state.new_timeversions

	return (
	    <div className="new-timeversions">
		{list.map((tv:any) => this.render_instance(tv))}
	    </div>
	)
    }
    render_timeline(tup:any){
	let ot = tup.object_type
	return (
	    <div className="timeline " key={tup.id}>
		<div className="header" onClick={()=>{this.props.cb(tup)}}>
		    <div className="title">{ot.label} : {tup.title} [timeline]</div>
		</div>
		<div className="body">
		{Object.entries(tup.timeversions).map(([k,tv]:[string,any]) =>
		    this.render_instance(tv))
		}
	    {this.render_new_timeversions(tup.id)}
	        <InputAddTimeversion
		    tup={tup}
		    cb={(tv:any) => {
			let prev = this.state.new_timeversions
		
			this.setState({new_timeversions: [...prev, tv]})
		    }}
		/>
		    </div>
	    </div>
	)
    }

    render(){
	let tup = this.props.tup
	return (
	    <div>{this.render_timeline(tup)}</div>
	)
    }
}
export default TimelineRow


